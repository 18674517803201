import logowhite from "./img/logo-white.png";
import logoblack from "./img/logo-black.png";
import { css } from "@emotion/css";

const logoSrc = {
  white: logowhite,
  black: logoblack,
};

type ImageProps = {
  src: string;
  className?: string;
  alt: string;
  children?: undefined;
};

const Image: React.FC<ImageProps> = ({ src, className, alt }) => {
  return <img src={src} className={className} alt={alt} />;
};

type LogoProps = {
  color: "black" | "white";
  className: string;
};

export const LogoStyles = {
  medium: css({
    width: "96px",
  }),
};

const Logo: React.FC<LogoProps> = ({ color, className }) => {
  return <Image className={className} src={logoSrc[color].src} alt="logo" />;
};

export { Image, Logo };
