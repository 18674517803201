import { FCWithChildren } from "@/Props";
import { Logo } from "@/components/Image";
import { css } from "@emotion/css";

const styles = {
  logo: css({
    height: "100px",
  }),
};

const MaintenancePage: FCWithChildren = () => {
  return (
    <div className="text-center mt-5">
      <Logo color={"black"} className={styles["logo"]} />
      <h3>ขณะนี้ระบบกำลังอยู่ในระหว่างการพัฒนา</h3>
      <h3>หากต้องการสอบถามเกี่ยวกับการจองห้องเอกเขนก กรุณาติดต่อ Line: @xkhanek</h3>
    </div>
  );
};

export default MaintenancePage;
