import type { NextPage } from "next";
import Home from "./home/home.index";
import MaintenancePage from "./maintenance/maintenance.index";

const Index: NextPage = () => {
  // return <Home />;
  return <MaintenancePage />;
};

export default Index;
